import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  InputGroup,
  ButtonGroup,
  OverlayTrigger,
  Button,
  Badge,
  Tooltip as TooltipBS,
} from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs";
import AuthContext from "../../context/AuthContext";
import PersonGraph from "../Graph/PersonGraph";
import Loader from "../../util/Loader";
import { BsPencilSquare } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import EditExpenceModal from "./EditExpenceModal";

function formatNumber(value) {
  if (value % 1 === 0) {
    return value.toString();
  } else {
    return value.toFixed(2).replace(/\.?0+$/, "");
  }
}

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default function AdvancedView() {
  const [income, setIncome] = useState();
  const [incomeBTW, setIncomeBTW] = useState();
  const [allExpences, setAllExpences] = useState(6000);
  const [expenceBTW, setExpenceBTW] = useState();
  const [taxableIncome, setTaxableIncome] = useState();
  const [deductibleExpences, setDeductibleExpences] = useState();
  const [nonDeductibleExpences, setNonDeductibleExpences] = useState();
  const [vaa, setVaa] = useState();
  const [tax, setTax] = useState();
  const [socialTax, setSocialTax] = useState();
  const [netIncome, setNetIncome] = useState();
  const [brutoCompanyOwner, setBrutoCompanyOwner] = useState();
  const [showPerMonth, setShowPerMonth] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentExpence, setCurrentExpence] = useState();
  const [simulations, setSimulations] = useState([]);
  const [currentSimulation, setCurrentSimulation] = useState();

  const { user, backendApi, } = useContext(AuthContext);

  const minQuartarlySocialTax = 866;
  const taxFreeAmount = 9270;
  const gemeentePercentage = 0.07;
  const taxBrackets = [
    { limit: 15200, rate: 0.25 },
    { limit: 26830, rate: 0.4 },
    { limit: 46440, rate: 0.45 },
    { limit: Infinity, rate: 0.5 },
  ];

  const saveExpense = (expence) => {
    if (expence.id) {
      setCurrentSimulation((prev) => ({
        ...prev,
        expenceCategories: currentSimulation.expenceCategories.map((c) =>
          c.id === expence.id ? { ...{ id: expence.id }, ...expence } : c,
        ),
      }));
    } else {
      expence.id = uuidv4();
      setCurrentSimulation((prev) => ({
        ...prev,
        expenceCategories: [...currentSimulation.expenceCategories, ...[expence]],
      }));
    }
    setShowEdit(false);
  };

  const removeExpence = (id) => {
    setCurrentSimulation((prev) => ({
      ...prev,
      expenceCategories: currentSimulation.expenceCategories.filter((c) => c.id !== id),
    }));
    //setExpenceCategories(expenceCategories.filter((c) => c.id !== id));
    setShowEdit(false);
  };

  // const removeSimulation = async (id) => {
  //   console.log(`removing ${id}`);
  //   const deleteAction = () =>
  //     setExpenceCategories(expenceCategories.filter((c) => c.id !== id));
  //   if (user) {
  //     await backendApi.delete(`/simulations/${id}`).then(deleteAction);
  //   } else {
  //     deleteAction();
  //   }
  //   setShowEdit(false);
  // };

  const saveSimulation = async (id, simulation) => {
    if (user) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      //debounce(async () => {
        await backendApi.put(`/simulations/${id}`, simulation);
      //}, 10000)();
    }
  };

  const newSimulation = async () => {
    const init = {
      income: 24000,
      expenceCategories: [],
      btwPlichtig: true,
      vennootschap: false,
      netCompanyOwner: 2000,
    }
    if (user) {
      await backendApi.post(`/simulations/`, init).then((res) => {
        console.log(res.data);
        setSimulations([...simulations, ...[res.data]]);
      });
    } else {
      init.id = uuidv4();
      setSimulations([...simulations, ...[init]]);
    }
    setCurrentSimulation(init);
  };

  //------------ use effects ----------------

  useEffect(() => {
    if(user){
      setLoading(true); 
      backendApi.get(`/simulations`).then((res) => {
        setSimulations(res.data);
        if (Array.isArray(res.data) && res.data.length > 0) {
            setCurrentSimulation(res.data[0]);
        }
        setLoading(false);
      });
    } else {
      //load from local storage
      const savedSimulation = localStorage.getItem('currentSimulation');
      
      if (savedSimulation) {
        let simulation = JSON.parse(savedSimulation);
        console.log(simulation);
        setCurrentSimulation(simulation);
      } else {
        newSimulation();
      }
    }
  } , [user]);
  
  useEffect(() => {
    if (currentSimulation?.id){
      if(!user){
        //save to localstorage
        localStorage.setItem('currentSimulation', JSON.stringify(currentSimulation));
      }
      setSimulations(simulations.map((s) =>
        s.id === currentSimulation.id ? { ...{ id: currentSimulation.id }, ...currentSimulation } : s,
      ));
    }
  }, [currentSimulation]);

  useEffect(() => {
    if(!isNaN(income)){
      setIncome(income * (showPerMonth ? 1 / 12 : 12));
    }

    //----- miss use showPerMonth to persist to db here, should be a save button.
    if (currentSimulation?.id){
      saveSimulation(currentSimulation.id, currentSimulation);
    }
    //-------
  }, [showPerMonth]);

  useEffect(() => {
    if (!isNaN(income)) {
      setCurrentSimulation(
        prev => ({...prev, income: (income * (showPerMonth ? 12 : 1))})
      );
    }
  }, [income]);

  //default per year
  useEffect(() => {
    if(currentSimulation){
      setIncome(currentSimulation.income / (showPerMonth ? 12 : 1));
      let income = currentSimulation.income || 0;
      let expenceCategories = currentSimulation.expenceCategories || [];
      let btwPlichtig = currentSimulation.btwPlichtig || false;
      let vennootschap = currentSimulation.vennootschap || false;
      let netCompanyOwner = currentSimulation.netCompanyOwner || 0;

      const incomeBTW = btwPlichtig ? income * 0.21 : 0;

      expenceCategories.forEach((cat) => {
        cat.amountIncBTW = cat.amount;
        cat.amountBTW = cat.amountIncBTW * ((cat.btw ?? 0) / 100);
        cat.amountExcBTW = cat.amountIncBTW - cat.amountBTW;

        cat.deductableBTW = btwPlichtig
          ? cat.amountBTW * ((cat.btwDeductibility ?? 100) / 100)
          : 0;
        cat.nonDeductibleBTW = btwPlichtig
          ? cat.amountBTW * ((100 - cat.btwDeductibility ?? 100) / 100)
          : cat.amountBTW;

        cat.deductible = cat.amountExcBTW * (cat.taxDeductibility / 100);
      });

      const allExpences = expenceCategories.reduce((acc, cat) => {
        if (cat.period === "maand")
          return acc + (cat.amountExcBTW + cat.nonDeductibleBTW) * 12;
        if (cat.period === "jaar")
          return acc + (cat.amountExcBTW + cat.nonDeductibleBTW);
        if (cat.period === "kwartaal")
          return acc + (cat.amountExcBTW + cat.nonDeductibleBTW) * 4;
        return acc;
      }, 0);

      const expenceBTW = btwPlichtig
        ? expenceCategories.reduce((acc, cat) => {
            if (cat.period === "maand") return acc + cat.deductableBTW * 12;
            if (cat.period === "jaar") return acc + cat.deductableBTW;
            if (cat.period === "kwartaal") return acc + cat.deductableBTW * 4;
            return acc;
          }, 0)
        : 0;

      const vaa = expenceCategories.reduce((acc, cat) => {
        if (cat.period === "maand") return acc + Number(cat.vaa) * 12;
        if (cat.period === "jaar") return acc + Number(cat.vaa);
        if (cat.period === "kwartaal") return acc + Number(cat.vaa) * 4;
        return acc;
      }, 0);

      const deductibleExpences = expenceCategories.reduce((acc, cat) => {
        if (cat.period === "maand") return acc + cat.deductible * 12;
        if (cat.period === "jaar") return acc + cat.deductible;
        if (cat.period === "kwartaal") return acc + cat.deductible * 4;
        return acc;
      }, 0);

      const nonDeductibleExpences = Math.max(0, allExpences - deductibleExpences);

      const socialTax = Math.max(
        (income - incomeBTW - expenceBTW - deductibleExpences) * 0.205,
        minQuartarlySocialTax * 4,
      );

      let taxableIncome =
        income - incomeBTW - expenceBTW - deductibleExpences - socialTax + vaa;

      let tax = 0;
      let previousLimit = 0;

      if(vennootschap){
        let personalTax = netCompanyOwner / (1 + gemeentePercentage);
        let taxable = 0;

        //TODO fix function
        for (const bracket of [...taxBrackets].reverse()) {
          if (personalTax <= 0) break;
          const limitDifference = bracket.limit - previousLimit;
          const taxableAmount = Math.min(personalTax / bracket.rate, limitDifference);
          taxable += taxableAmount;
          personalTax -= taxableAmount * bracket.rate;
          previousLimit = bracket.limit;
        }
        const brutoCompanyOwner = taxable

        taxable = taxable - brutoCompanyOwner;
        setBrutoCompanyOwner(brutoCompanyOwner);
        console.log(brutoCompanyOwner);

        tax = taxableIncome * 0.25;

      }else{
        for (const bracket of taxBrackets) {
          if (taxableIncome - taxFreeAmount > previousLimit) {
            const taxableAmount =
              Math.min(taxableIncome - taxFreeAmount, bracket.limit) -
              previousLimit;
            tax += taxableAmount * bracket.rate;
            previousLimit = bracket.limit;
          } else {
            break;
          }
        }
        tax += gemeentePercentage * tax;
      }

      const companyOwnerTax = brutoCompanyOwner - netCompanyOwner

      const netIncome =
        (income - allExpences - expenceBTW - incomeBTW - socialTax - tax - vaa - (vennootschap? netCompanyOwner - companyOwnerTax: 0)) * (vennootschap? 0.9: 1);

      setIncomeBTW(incomeBTW);
      setAllExpences(allExpences);
      setExpenceBTW(expenceBTW);
      setVaa(vaa);
      setNonDeductibleExpences(nonDeductibleExpences);
      setDeductibleExpences(deductibleExpences);
      setSocialTax(socialTax);
      setTaxableIncome(taxableIncome);
      setTax(tax + (vennootschap ? companyOwnerTax : 0));
      setNetIncome(netIncome);
    }
  }, [currentSimulation]);

  const handleOpenEdit = (category) => {
    setCurrentExpence(category);
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setCurrentExpence(null);
    setShowEdit(false);
  };

  if (loading) return <Loader />;
  return (
    <Container className="px-5 pt-5">
      {/* {JSON.stringify(simulations)} */}
      <Row>
        <Col
          lg={6}
          className="p-0 d-flex align-items-center justify-content-center"
        >
          <Card className="m-3 p-5 w-100" style={{ borderWidth: 0 }}>
            <div className="d-flex justify-content-center mb-3">
              <ButtonGroup type="radio" name="options" defaultValue={1}>
                <Button
                  variant={showPerMonth ? "primary" : "secondary"}
                  name="month"
                  onClick={() => setShowPerMonth(true)}
                >
                  Maand
                </Button>
                <Button
                  variant={!showPerMonth ? "primary" : "secondary"}
                  name="year"
                  onClick={() => setShowPerMonth(false)}
                >
                  Jaar
                </Button>
              </ButtonGroup>
            </div>

            <Form.Group className="mb-3">
              <Form>
                <Form.Group>
                  <Form.Label className="fw-bold">
                    Inkomsten <small className="fw-normal">(incl. btw)</small>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      value={(income??"").toString().replace(/\./g, ",")}
                      onChange={(e) => setIncome(e.target.value.replace(/,/g, "."))}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-bold">
                    Uitgave{" "}
                    <small className="fw-normal">
                      (incl. btw) : €{" "}
                      {formatNumber(
                        (allExpences + expenceBTW) *
                          (showPerMonth ? 1 / 12 : 1),
                      )
                        .toString()
                        .replace(/\./g, ",")}
                    </small>
                  </Form.Label>
                  <Form.Text className="fw-bold"></Form.Text>
                  <div className="mb-3">
                    {(currentSimulation?.expenceCategories || []).map((cat) => (
                      <Badge
                        bg="danger"
                        key={cat.id}
                        pill
                        className="m-1 category"
                        onClick={() => handleOpenEdit(cat)}
                      >
                        {cat.name} <BsPencilSquare />
                      </Badge>
                    ))}
                    <Badge
                      bg="secondary"
                      pill
                      className="m-1 category"
                      text="dark"
                      onClick={() => handleOpenEdit(null)}
                    >
                      +
                    </Badge>
                    <EditExpenceModal
                      show={showEdit}
                      expence={currentExpence}
                      handleClose={handleCloseEdit}
                      save={saveExpense}
                      remove={removeExpence}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Form.Group>
            <InputGroup className="mb-2">
              <Form.Check
                type={"switch"}
                label={`Ik ben BTW-plichtig`}
                checked={currentSimulation?.btwPlichtig}
                onChange={(e) => setCurrentSimulation(prev => (
                  {...prev, btwPlichtig: e.target.checked}
                ))}
              />
              <OverlayTrigger
                className="text-muted"
                placement="bottom"
                overlay={
                  <TooltipBS>
                    Als je meer dan € 25.000 omzet per jaar maakt, moet je BTW
                    betalen. Het eerste jaar dat je dit bedrag overschrijdt, ben
                    je niet BTW-plichtig.
                  </TooltipBS>
                }
              >
                <span className="mx-2">
                  <small>
                    <BsQuestionCircle />
                  </small>
                </span>
              </OverlayTrigger>
            </InputGroup>
           
          </Card>
        </Col>
        <Col lg={6} className="p-0">
          <Card className="m-3 pb-5" style={{ borderWidth: 0 }}>
            <PersonGraph
              showPerMonth={showPerMonth}
              btwPlichtig={currentSimulation?.btwPlichtig}
              income={currentSimulation?.income}
              incomeBTW={incomeBTW}
              allExpences={allExpences}
              expenceBTW={expenceBTW}
              nonDeductibleExpences={nonDeductibleExpences}
              deductibleExpences={deductibleExpences}
              socialTax={socialTax}
              tax={tax}
              netIncome={netIncome}
            ></PersonGraph>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
