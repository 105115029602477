import React from "react";
import { createContext, useState, useEffect } from "react";

import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const backendApi = axios.create({
    baseURL: "https://i3hgz12ay4.execute-api.eu-west-1.amazonaws.com" ,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    backendApi.get("/auth/userinfo")
      .then(response => {
        if (response.status === 200) {
          setUser(response.data.user);
        }else{
          setUser(null);
        }
      }).catch(error => {
        setUser(null);
        console.error("Fetch user info error", error);
      }).finally(() => {
        setLoading(false);
      });
  };

  const googleLogin = (credentialResponse) => {
    backendApi.post("/auth/google", credentialResponse)
      .then(response => {
        if (response.status === 200) {
          setUser(response.data.user);
        }
      })
      .catch(error => {
        console.error("Login error", error);
      })
  };

  const logout = () => {
    backendApi.post("/auth/logout")
      .then(response => {
        if (response.status === 200) {
          setUser(null);
        }
      })
      .catch(error => {
        console.error("Logout error", error);
      })
  };
  // const googleLoginCode = useGoogleLogin({
  //   prompt: "consent",
  //   flow: "auth-code",
  //   onSuccess: async (codeResponse) => {
  //     axios
  //       .post(
  //         "https://i3hgz12ay4.execute-api.eu-west-1.amazonaws.com/auth/google",
  //         { code: codeResponse.code },
  //       )
  //       .then((resp) => {
  //         setIdToken(resp.data.tokens.id_token);
  //         setAccessToken(resp.data.tokens.access_token);
  //         setRefreshToken(resp.data.tokens.refresh_token);
  //         localStorage.setItem("id_token", resp.data.tokens.id_token);
  //         localStorage.setItem("access_token", resp.data.tokens.access_token);
  //         localStorage.setItem("refresh_token", resp.data.tokens.refresh_token);

  //         let user = jwtDecode(resp.data.tokens.id_token);
  //         setUser(user);
  //         localStorage.setItem("picture_url", user.picture);
  //       });
  //   },
  //   onError: (errorResponse) => console.log(errorResponse),
  // });

  // let loginUser = async (e) => {
  //   e.preventDefault();
  //   let response = await fetch("http://127.0.0.1:8000/api/token/", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       username: e.target.username.value,
  //       password: e.target.password.value,
  //     }),
  //   });
  //   let data = await response.json();

  //   if (response.status === 200) {
  //     setAuthTokens(data);
  //     setUser(jwtDecode(data.access));
  //     localStorage.setItem("authTokens", JSON.stringify(data));
  //   } else {
  //     alert("Something went wrong!");
  //   }
  // };

  // const refreshTokens = async () => {
  //   const resp = await fetch(`${BASE_URL}/auth/google/refresh-token`, {
  //     method: "POST", headers: {"Content-Type": "application/json"},
  //     body: JSON.stringify({
  //       refreshToken: refreshToken,
  //     })
  //   });
  //   const response = await resp.json()
  //   setIdToken(response.credentials.id_token);
  //   setAccessToken(response.credentials.access_token);
  //   setRefreshToken(response.credentials.refresh_token);
  //   localStorage.setItem("id_token", idToken);
  //   localStorage.setItem("access_token", accessToken);
  //   localStorage.setItem("refresh_token", refreshToken);
  // }

  axios.interceptors.request.use(async (request) => {
    const cookies = request.headers.Cookie || request.headers.cookie;
    console.log(cookies);
    // if (request.url.includes(BASE_URL) && !request.url.includes(`${BASE_URL}/auth`)) {
    //   const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;
    //   if(isExpired){
    //     await refreshTokens();
    //   }
    //   if (!idToken){
    //     return request;
    //   }
    //   request.headers.Authorization = `Bearer ${idToken}`;
    // }
    return request;
  });

  return (
    <AuthContext.Provider
      value={{
        backendApi,
        user,
        getUserInfo,
        googleLogin,
        logout,
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
